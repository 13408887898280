//Copied from https://github.com/twbs/bootstrap/blob/main/scss/forms/_form-check.scss
//Need to delete after upgrade to BS 5.2

//
// Check/radio
//
.form-check-reverse {
  padding-right: $form-check-padding-start;
  padding-left: 0;
  text-align: right;

  .form-check-input {
    float: right;
    margin-right: $form-check-padding-start * -1;
    margin-left: 0;
  }
}

//
// Switch
//

.form-switch {
  &.form-check-reverse {
    padding-right: $form-switch-padding-start;
    padding-left: 0;

    .form-check-input {
      margin-right: $form-switch-padding-start * -1;
      margin-left: 0;
    }
  }
}

@mixin inner-border($color, $inset: -3.5px, $thickness: 1.5px) {
  $absInset: abs($inset);
  outline: $thickness solid $color;
  outline-offset: -($absInset);
}

@mixin lowes-button($bg, $border, $text: $white) {
  background-color: $bg;
  border-color: $border;
  border-radius: 0;
  color: $text;

  .fa,
  [class^='wts__i--'] {
    color: $text;
  }
}

@mixin lowes-btn-states(
  $default-bg,
  $default-border,
  $default-text,
  $hover-bg,
  $hover-border,
  $hover-text,
  $active-bg,
  $active-border,
  $active-text,
  $focus-bg,
  $focus-border,
  $focus-text,
  $focus-outline: $white,

  $disabled-bg: $lowes-disabled,
  $disabled-text: $lowes-disabled-text
) {
  &,
  &:link,
  &:visited {
    @include lowes-button($default-bg, $default-border, $default-text);
  }

  &:hover,
  &.hover,
  &.active:hover,
  &.active.hover,
  &:active:hover,
  &.focus:hover {
    @include lowes-button($hover-bg, $hover-border, $hover-text);
  }

  &:active,
  &.active {
    @include lowes-button($active-bg, $active-border, $active-text);
  }

  &:focus,
  &.focus {
    @include lowes-button($focus-bg, $focus-border, $focus-text);
    @include inner-border($focus-outline);
  }

  &.disabled,
  &.disable {
    @include lowes-button($disabled-bg, $disabled-bg, $disabled-text);
  }
}

/* Standard button styles from the Lowe's style guide*/

@mixin lowes-btn-interactive {
  @include lowes-btn-states(
    // Default
    $lowes-interactive-01,
    $lowes-interactive-01,
    $white,
    // Hover
    $lowes-interactive-02,
    $lowes-interactive-02,
    $white,
    // Active
    $lowes-interactive-03,
    $lowes-interactive-03,
    $white,
    // Focus
    $lowes-interactive-01,
    $lowes-interactive-01,
    $white,
    $white,
    // Disabled
    tint-color($lowes-interactive-01, 20%),
    shade-color($lowes-interactive-01, 40%)
  );
}

@mixin lowes-btn-interactive-secondary {
  @include lowes-btn-states(
    //default
    rgba(255, 255, 255, 0),
    $lowes-interactive-01,
    $lowes-interactive-01,
    //hover
    $lowes-ui-00,
    $lowes-interactive-02,
    $lowes-interactive-02,
    //active
    $lowes-ui-01,
    $lowes-interactive-03,
    $lowes-interactive-03,
    //focus
    rgba(255, 255, 255, 0),
    $lowes-interactive-01,
    $lowes-interactive-01,
    //disabled
  );
}

@mixin lowes-btn-commerce {
  @include lowes-btn-states(
    //default
    $lowes-commerce-01,
    $lowes-commerce-01,
    $white,
    //hover
    $lowes-commerce-02,
    $lowes-commerce-02,
    $white,
    //active
    $lowes-commerce-03,
    $lowes-commerce-03,
    $white,
    //focus
    $lowes-commerce-01,
    $lowes-commerce-01,
    $white,
    //disabled
  );
}

@mixin lowes-btn-commerce-secondary {
  @include lowes-btn-states(
    //default
    $white,
    $lowes-commerce-01,
    $lowes-commerce-01,
    //hover
    $lowes-ui-00,
    $lowes-commerce-02,
    $lowes-commerce-02,
    //active
    $lowes-ui-01,
    $lowes-commerce-03,
    $lowes-commerce-03,
    //focus
    $white,
    $lowes-commerce-01,
    $lowes-commerce-01,
    $lowes-ui-03 //disabled
  );
}

@mixin lowes-btn-contrast {
  @include lowes-btn-states(
    $transparent,
    $lowes-ui-045,
    $lowes-ui-045,
    $lowes-ui-01,
    $lowes-ui-045,
    $lowes-ui-045,
    $lowes-ui-02,
    $lowes-ui-05,
    $lowes-ui-05,
    $transparent,
    $lowes-ui-045,
    $lowes-ui-045,
    $lowes-ui-04
  );
}

/* Custom button styles from the Lowe's Omni Style Guide */

@mixin lowes-btn-nav {
  @include lowes-btn-states(
    //default
    $lowes-ui-01,
    $lowes-ui-01,
    $lowes-ui-04,
    //hover
    $lowes-interactive-02,
    $lowes-interactive-02,
    $white,
    //active
    $lowes-interactive-03,
    $lowes-interactive-03,
    $white,
    //focus
    $lowes-ui-01,
    $lowes-ui-01,
    $lowes-ui-04,
    $lowes-ui-04 //disabled
  );
}

// Mix-in for configurator steps.
@mixin step-arrow($bg, $text, $hover-bg, $hover-text) {
  color: $text;
  background-color: $bg;

  &:after {
    background-color: $bg;
  }

  &:focus {
    color: $text;
    background-color: $bg;

    &:after {
      background-color: $bg;
    }
  }

  &:hover,
  &.hover {
    color: $hover-text;
    background-color: $hover-bg;

    &:after {
      background-color: $hover-bg;
    }
  }
}

.navbar-expand-lg {
  .navbar-nav {
    .dropdown-menu {
      li#navbar__ {
        &generalSettings,
        &pricingPreferences,
        &paperworkHeaders,
        &separator {
          display: none;
        }
      }
    }
  }
}

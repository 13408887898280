.details {
  @include spinner;

  &__description {
    margin-bottom: 2rem;
  }
}

.drawing {
  position: relative;
  @include spinner;

  &__image {
    padding: 1rem;

    img {
      box-shadow: 0 1rem 2rem rgba($secondary, .4);
      border-radius: $border-radius;
      max-height: 25rem;
    }
  }
}

.drawing__icon--upload {
  >label {
    cursor: pointer;
  }
}

.attachments {
  position: relative;
  word-break: break-word;

  @include spinner;

  &__dropzone {
    min-height: 8rem;

    div {
      text-align: center;
      @include absCenter;
    }

    &--disabled {
      opacity: .65;
      cursor: not-allowed;

      span {
        cursor: inherit;
      }
    }
  }

  &__table {
    & > tbody > tr {
      border-right: 1px solid rgba($secondary, .5);

      td {
        text-align: left;
        border: none;
      }

      .attachments__fileName, .attachments--download, .attachments--trash {
        cursor: pointer;
      }

      i {
        &:not(last-child) {
          padding-right: 1rem;
        }
      }

      .attachments--download, .attachments--trash {
        opacity: 0;
        transition: all .5s;
      }

      &:hover {
        background-color: $gray-200;

        .attachments--download, .attachments--trash {
          opacity: 1;
        }
      }
      span {
        padding-right: 1rem;
      }
    }
  }
}

.notes {
  @include spinner;
}

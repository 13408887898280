.custom-landing-page-tile {
  table > tbody > tr {
    &:hover {
      cursor: pointer;
      background: $lowes-light-blue;
    }
  }

  &.recent-quotes {
    .recent-quotes__view-more {
      justify-content: center;

      > span {
        text-align: center;
      }
    }
  }

  .quote-status {
    text-transform: capitalize;
  }
}

.content-home {
  p small {
    font-style: italic;
  }
}

.home-banner {
  a {
    background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/lowes/banner.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: 300px;
    display: block;
  }
}

.home-page-links {
  display: flex;
  justify-content: space-between;
}

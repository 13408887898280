.WtsHR {
  position: relative;

  &__text {
    display: inline-block;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 10px;
    background-color: $wts-highlight-reverse;
  }

  .link {
    display: inline-block;

    @include absCenter;

    padding: 6px 10px;
    background-color: $gray-200;
    border-radius: $border-radius;

    transition: all .5s;

    span {
      padding-right: .3em;
      opacity: .6;

      &:hover {
        opacity: .8;
      }
    }

    &:hover {
      background-color: $gray-200;
    }
  }

  hr {
    background-color: transparent;
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: $wts-text;
    text-align: center;
    height: 1.5em;
    opacity: .8;

    &:before {
      content: '';
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: linear-gradient(to right, transparent, $gray-500, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
  }
}

.my-quotes-grid__search {
  select {
   border-color: $input-border-color;
  }
}

.my-quotes-grid__perPage {
  display: inline-block;
  align-items: center;
  margin-left: 1rem;

  select {
    display: inline-block;
    width: auto;
    margin: 0 0.2rem;
  }
}

.my-quotes-grid__action-buttons {
  display: flex;
  justify-content: flex-end;

  a:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.my-quotes__toggleTitle {
  font-weight: 100;
}

.grid__sortableColumn {
  cursor: pointer;
  white-space: nowrap;
  i {
    margin-left: 0.1rem;
  }
}

.grid__sortableIcon {
  position: relative;
  opacity: 0.3;
  margin-left: 0.2em;
  top: 0.12em;

  svg[data-icon] {
    position: absolute;
    top: 0.1em;
  }
  svg[data-icon]:last-child {
    bottom: 0.12em;
  }
}

.grid__selectedSortableIcon {
  position: relative;
  opacity: 1;
  margin-left: 0.2em;
  svg[data-icon] {
    position: absolute;
  }
  svg[data-icon]:last-child {
    bottom: 0.12em;
  }
}

.grid__perPage {
  display: inline-block;
  align-items: center;
  margin-left: 1em;

  select {
    display: inline-block;
    width: auto;
    margin: 0 .2em;
  }
}

.grid__dataCount {
  display: inline-block;
  margin: 20px 20px 20px 0px;
}

.grid__settings {
  border-radius: $border-radius;
  border: solid 1px $input-border-color;
}

.project-row {
  .card-footer {
    @include view_more;
  }
}

.my-quotes-table__projectHeader {
  cursor: pointer;

  &:hover {
    background-color: $gray-200;
  }
}

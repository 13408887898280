#configurator-app {
  .step-nav {
    li {
      a {
        padding-right: 1.5em;
      }

      /* move the step icons into a better position*/
      a span.fa,
      a [class^='wts__i--'] {
        position: absolute;
        right: 1px;
        display: block;
        margin: 0;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        color: $white;
      }

      a {
        @include step-arrow(
          $lowes-ui-04,
          $white,
          $lowes-interactive-01,
          $lowes-ui-02
        );
      }

      &.previous a,
      &.active a {
        @include step-arrow(
          $lowes-interactive-02,
          $white,
          $lowes-interactive-01,
          $lowes-ui-02
        );
      }
    }
  }

  .next-step-button {
    @include step-arrow(
      $lowes-interactive-02,
      $white,
      $lowes-interactive-01,
      $lowes-ui-02
    );
    position: relative;
    border: none;
    border-radius: 0;
    padding: 0.25em 1em 0.25em 1.8em;
    margin: 0;
    transition: none;
    padding-left: 0.75em;
  }

  .card-step-question {
    font-size: 0.9375em;
  }

  #quantityInput {
    width: 70px;
  }

  .fixed-bottom {
    .btn.btn-primary.btn-disabled.wts__rounded-button {
      background-color: #bfbfbf;
      border-color: $lowes-ui-05;
      color: $white;
    }

    .btn[title='Start Over'],
    #configurator__save-copy,
    #configurator__save-new {
      display: none;
    }

    .footer__actionItem {
      margin-right: 0;

      .btn[title='Cancel'] {
        margin-right: 20px;
      }
    }

    background-color: $lowes-ui-02;
    border-radius: 0;
  }

  label[for='quantityInput'] {
    color: $lowes-ui-05 !important;
  }

  .productName-arrow,
  .pricing-summary,
  .drawing-wrapper p.drawing-no-image,
  .drawingActions {
    border-radius: 0;
  }
}

#configurator-app .step-nav li a,
#configurator-app .step-nav li a::after {
  color: $lowes-config-step-gray;
  background-color: #f4f4f4;
}

#configurator-app .step-nav li a span.fa,
#configurator-app .step-nav li a [class^='wts__i--'] {
  color: $lowes-config-step-gray;
}

#configurator-app .step-nav li.previous a span.fa,
#configurator-app .step-nav li.active a span.fa,
#configurator-app .step-nav li.previous a [class^='wts__i--'],
#configurator-app .step-nav li.active a [class^='wts__i--'] {
  color: $white;
}

#configurator-app .step-nav li a:hover,
#configurator-app .step-nav li a:hover::after {
  color: $lowes-config-step-gray;
  background-color: #e0e0e0;
}

#configurator-app .step-nav li a:hover span.fa,
#configurator-app .step-nav li a:hover [class^='wts__i--'] {
  color: $lowes-config-step-gray;
}

#configurator-app .step-nav li.previous:hover a,
#configurator-app .step-nav li.active:hover a,
#configurator-app .step-nav li.previous:hover a::after,
#configurator-app .step-nav li.active:hover a::after,
#configurator-app .step-nav li.previous:hover a span.fa,
#configurator-app .step-nav li.active:hover a span.fa,
#configurator-app .step-nav li.previous:hover a [class^='wts__i--'],
#configurator-app .step-nav li.active:hover a [class^='wts__i--'] {
  color: $white;
}

#configurator-app .step-nav li.previous,
#configurator-app .step-nav li.active {
  svg.step-complete {
    color: $configurator-check-green;
  }

  svg.stop-type {
    color: $configurator-warning-red !important;
  }
}

.global-changes {
  .footer {
    background: $lowes-ui-03;
  }
}

.step-answer-pref .wts__i--star-o {
  display: none;
}

#configurator-app {
  #guided-shopping-sidebar {
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .toggle-symbol {
        font-weight: bolder;
        font-size: larger;
      }
    }

    .card-body {
      .toggle-text {
        font-weight: 500;
      }
    }
  }
}

.estimate-match-container {
  .card {
    padding: 0;
  }
}

.btn-submit,
.btn-secondary,
.btn-primary,
.btn-success,
.btn-success.dropdown-toggle,
.open .btn-success.dropdown-toggle {
  @include lowes-btn-interactive;
}

.btn-default,
.btn-light,
.btn-secondary,
.btn-outline-secondary {
  @include lowes-btn-contrast;
}

// Set the card header link button text color.
.card-header .btn-link {
  color: $gray-600;
}

// Set the card header link button text color on line items.
#line-items-app .card-header .btn-link {
  color: $lowes-interactive-02;
}

// Set the BS link button to respond to focus similar to a button.
a.btn:focus:not(:focus-visible) {
  outline: 0;
}

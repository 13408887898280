// Accordion overrides
.accordion-button {

  // Remove the right-side caret, this class adds its own on the left.
  &[data-bs-toggle~="collapse"],
  &.controlled-collapse-trigger {
    &:before {
      text-align: center; // accordion-button sets left align which messes up this icon.
    }

    &:after {
      display: none;
    }
  }
}

.quote-header__clientIntegrationId,
.quote-header__clientName {
  display: inline-block;
  margin-left: 0.3rem;
}

.quoteheader__details .form-group {
  word-break: break-word;
}

.quote--header {
  display: grid;
  grid-template-columns: 1fr 1.5em; // Space is for menu.
  gap: 0.5em;
}

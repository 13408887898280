// Table reimplementation to allow for per-row forms. DO NOT USE to replace tables.
// @see Bootstrap 3's _tables.scss

div.fake-table {
  @extend .table;
  background-color: $table-bg;
  display: table;

  span.fake-table-th {
    font-weight: bold;
  }

  span.fake-table-th,
  span.fake-table-td {
    text-align: left;

    &[class*="col-"] {
      position: static;
      display: table-cell;
      float: none;
    }
  }

  div.fake-table-thead {
    display: table-header-group;
  }
  div.fake-table-tbody {
    display: table-row-group;
  }
  div.fake-table-tfoot {
    display: table-footer-group;
  }

  .fake-table-tr {
    display: table-row;

    span.fake-table-th,
    span.fake-table-td {
      display: table-cell;
    }
  }

  // Cells
  div.fake-table-thead,
  div.fake-table-tbody,
  div.fake-table-tfoot {
    .fake-table-tr {
      span.fake-table-th,
      span.fake-table-td {
        padding: $table-cell-padding-y $table-cell-padding-x;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
      }
    }
  }

  // Bottom align for column headings
  div.fake-table-thead .fake-table-tr span.fake-table-th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }

  // Remove top border from thead by default
  div.fake-table-thead:first-child .fake-table-tr:first-child {
    span.fake-table-th,
    span.fake-table-td {
      border-top: 0;
    }
  }

  // Condensed table w/ half padding
  &.table-sm {
    div.fake-table-thead,
    div.fake-table-tbody,
    div.fake-table-tfoot {
      .fake-table-tr {
        span.fake-table-th,
        span.fake-table-td {
          padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
        }
      }
    }
  }

  // Zebra-striping
  &.table-striped {
    div.fake-table-tbody {
      > .fake-table-tr:nth-of-type(odd) {
        background-color: $table-accent-bg;
      }
    }
  }

  // Hover effect
  &.table-hover {
    div.fake-table-tbody .fake-table-tr:hover {
      background-color: $table-hover-bg;
    }
  }
}
.securitySettingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.securitySettingsHeader, .group__actionHeader {
  box-shadow: 0 1px 1px $gray-200;
  padding-bottom: .5em;
  margin-bottom: 1em;
}

.group-new {
  label {
    margin-right: 5px;
  }
}

.groupSecuritySetting-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  label {
    font-weight: 300;
  }
}

.groupSecuritySetting__info-link {
  @extend .ms-1;
}

.component--no-selection {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .rounded;
  @extend .h-100;
  background-color: $gray-200;
}
.homepage-grid__tile {
  min-height: 25em;
  height: 100%;
}

.homepage-grid-configurable {
  @extend .nav-tiles-grid__minMax--300--lg;

  > .homepage-grid__tile-configurable {
      div {
        background-size: cover;
        position: relative;
        min-height: 25em;

        @extend .d-flex;
        @extend .flex-column;
        @extend .align-items-center;

        > h2 {
          background-color: $wts-highlight-reverse;
          color: $wts-text;

          padding: 4px 8px;
          margin: 0;
          position: absolute;
          bottom: 0;

          @extend .text-center;
          border-radius: $border-radius;
        }
      }
  }
}
#patch-area-grid {
  span.select-country-wrapper {
    padding-left: 1em;
  }
  .total-stores-counter {
    text-align: right;
    .counter {
      font-weight: bold;
    }
  }

  .table-patcharea {
    overflow: auto;
    max-height: 60vh;
    padding: 0;

    div.patcharea-header {
      cursor: pointer;
      input {
        margin-left: 0.5em;
      }

      span.patcharea-name {
        margin-left: 0.5em;
        display: inline-block;
        min-width: 25px;
        text-align: center;
      }
    }
  }

  .table-patcharea > table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $white;
  }

  .table-patcharea > table > tbody > tr > td {
    padding: 0;

    & > div {
      padding: 5px;
    }
  }

  .has-selected {
    background-color: $list-selected;
  }
}

// Utilities for visually truncating text after a fixed number of lines.
// Utilities for clamping text up to 6 lines are generated.
// Use the line-clamp-{breakpoint}-{n} utilities to specify how many lines of
// text should be visible before truncating.
//
// Sample class names line-clamp-1, line-clamp-sm-1, line-clamp-md-1,
// line-clamp-2, line-clamp-sm-2, line-clamp-md-2 etc.
//
// <p class="line-clamp-2 line-clamp-lg-6">
//    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//    tempor incididunt ut labore et dolore magna aliqua.
// </p>
//
// To remove any line-clamping, use line-clamp-none, line-clamp-sm-none etc.
// Note that the line-clamp{breakpoint}-{n} sets other properties like display
// and overflow in addition to -webkit-line-clamp which are not unset by
// line-clamp-none, so depending on what you are trying to achieve you may need
// to explicitly override those properties with utilities like flex or
// overflow-visible as well.
//
// Reference: https://github.com/tailwindlabs/tailwindcss-line-clamp

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through 6 {
      .line-clamp#{$infix}-#{$i} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
      }
    }

    .line-clamp#{$infix}-none {
      -webkit-line-clamp: unset;
    }
  }
}


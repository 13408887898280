.address-summary
{
  margin-bottom:1em;
  
  &-header
  {
    position:relative; /*allow for absolute-local positioning*/
    .title
    {
      margin-right:100px;/*make room for the control buttons*/
    }
    .controls
    {
      position: absolute;
      top:0;
      right:0;
    }
  }
}

.address-list-selecting
{
  .address-current .address-summary
  {
    background-color:transparent;
  }
}
.cash-deposits {

  .cash-deposits__actions {
    .btn {
      i {
        position: relative;

        // Mix 2 font-awesome icons
        i {
          font-size: 7px;
          position: absolute;
          right: 50%;
          top: 50%;
          transform: translate(50%, -50%);
        }
      }
    }
  }
}
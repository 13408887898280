//default border radius is set in base app.scss using the core WebCP theme variables,
//but it doesn't hit all the places. The settings in this file are backup settings for where
//the border radii may be missed.

.form-control {
  border-radius: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .search-modal__submit,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .btn-group > .search-modal__submit,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .search-modal__submit:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn,
.input-group-btn:first-child
  > .btn-group:not(:first-child)
  > .search-modal__submit {
  border-radius: 0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0;
}

.Select-control {
  border-radius: 0;
}

.form-group-sm .form-control {
  border-radius: 0;
}

.focus-input {
  float: left;
  position: relative;

  &:focus {
    outline: none;
  }

  input[type="text"] {
    color: #333;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;

    &~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #3399FF;
      transition: 0.4s;
    }

    &:focus {
      outline: none;

      &~.focus-border {
        width: 100%;
        transition: 0.4s;
        left: 0;
      }
    }
  }
}
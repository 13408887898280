#my-quotes-app {
  .my-quotes-header {
    display: none;
  }

  form.form.card {
    display: flex;
    gap: 15px;
    align-items: flex-end;

    > .form-group {
      position: relative;

      > .input-clear {
        transform: translate(0, -50%);
        bottom: 0;
        top: unset;
        font-size: 1rem;
        height: 1.5rem;
      }
    }
  }

  .custom-quotes-table {
    .clickable-table-row {
      &:hover {
        cursor: pointer;
        background: $lowes-light-blue;
      }
    }
  }

  .custom-my-quotes-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .search-header {
      display: flex;

      .clear-filter {
        cursor: pointer;
        margin-left: 1.5rem;
        margin-top: 0.4rem;
        text-decoration: underline;
      }
    }

    .input-group.has-feedback {
      width: 100%;
    }
  }

  #projectNumber::-webkit-outer-spin-button,
  #projectNumber::-webkit-inner-spin-button {
    /* Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    margin: 0;
    /* Firefox */
    -moz-appearance: textfield;
  }

  .advanced-search-link {
    margin: -3% 1% 0% 0%;

    &:hover,
    &:focus {
      outline: none;
    }

    .btn-link {
      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  .advanced-search-submit-btn {
    background-color: $lowes-interactive-02;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }

    &.disabled {
      background: $lowes-light-gray;
    }
  }

  .lowes-quote-status-filter {
    border: none;
    background-color: $white;

    .isChecked {
      color: $lowes-interactive-01;
    }

    // Remove the dropdown caret on the status filter.
    &:after {
      content: none;
    }
  }

  .lowes-quote-status-list {
    li {
      .checkbox {
        label {
          width: 100%;
        }
      }
    }
  }

  $tabs-border-settings: 1px solid $wts-text;

  .quotes-tab-container {
    margin: 0;
    padding-left: 20px;
    display: flex;
    align-items: flex-end;
    border-bottom: $tabs-border-settings;

    li {
      position: relative;
      list-style-type: none;
      display: inline-block;

      span.tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 30px;
        background-color: $gray-300;
        border-top: $tabs-border-settings;
        border-left: $tabs-border-settings;
        border-right: $tabs-border-settings;
        color: $black;

        &:hover {
          background-color: $gray-300;
          cursor: pointer;
        }

        &.active {
          background-color: $white;
          border-bottom: none;
          height: 35px;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  span.toggle-hide-btn-container {
    display: block;
    width: 100%;
    margin: 35px 0 10px 0;

    button.toggle-hide {
      background-color: $lowes-interactive-02;
      color: $white;
      width: 100px;

      &:disabled {
        background-color: $lowes-light-gray;
        color: $btn-container-alice-blue;
      }
    }
  }
}

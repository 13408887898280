#quote__actions .btn.btn-primary:nth-child(5) {
  @include lowes-btn-interactive;
}

#quote__actions .btn.btn-primary:nth-child(6) {
  @include lowes-btn-commerce;
}

.breadcrumb {
  opacity: 1;

  li {
    color: $black;
  }

  li.active {
    color: $lowes-interactive-01;
    font-weight: bold;
  }
}

#line-items-app .wts-info {
  display: none;
}

#quote-tabs_company,
#quote-tabs_details,
#quote-tabs_notes,
#quote-tabs_attachments,
#quote-tabs_relations,
#order-tabs_details,
#order-tabs_company,
#order-tabs_notes,
#quote-header-app,
#order-tabs_attachments,
#order-tabs_relations,
#quote-project {
  display: none;
}

.quoteheader__details,
.quoteheader__shipping,
.quoteheader__billing {
  display: none;
}

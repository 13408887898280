.nav-tiles-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1em;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(5, 1fr);
  }

  > a {
    display: flex;

    > .card {
      flex: 1 1 auto;
      display: inline-block;
      max-width: 100%;
      min-width: 0;
    }
  }
}

.nav-tiles-grid__minMax--200-300 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  gap: 1em;
}

.nav-tiles-grid__minMax--300--lg {
  display: grid;
  gap: 1em;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
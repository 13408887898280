.modal-dialog {
  // See Bootstrap's _alert.scss
  @each $state, $value in $theme-colors {
    $alert-dialog-background: shift-color($value, $alert-bg-scale);
    $alert-dialog-border: shift-color($value, $alert-border-scale);
    $alert-dialog-color: shift-color($value, $alert-color-scale);
    @if (contrast-ratio($alert-dialog-background, $alert-dialog-color) < $min-contrast-ratio) {
      $alert-dialog-color: mix($value, color-contrast($alert-dialog-background), abs($alert-color-scale));
    }
    &.alert-dialog-#{$state} {
      .modal-header {
        background-color: $alert-dialog-background;
        border-color: $alert-dialog-border;
        color: $alert-dialog-color;

        .modal-title{
          color: $alert-dialog-color;
        }

        // Color the close button focus ring.
        .btn-close:focus {
          // See Bootstrap's mixins/_buttons.scss
          $color: color-contrast($value);
          @if $enable-shadows {
            @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $value, 15%), .5));
          } @else {
            // Avoid using mixin so we can pass custom focus shadow properly
            box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $value, 15%), .5);
          }
        }
      }
    }
  }
}

.wts-offcanvas {
  @extend .offcanvas;

  .offcanvas-header {
    @extend .bg-x-light;
    @extend .border-bottom;
  }

  // Fix for when a form has to wrap the offcanvas body and footer
  // (so footer can contain the form's submit button).
  > form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
}

.wts-offcanvas-backdrop {
  @extend .offcanvas-backdrop;
}

.wts-offcanvas-start {
  @extend .offcanvas-start;
}

.wts-offcanvas-end {
  @extend .offcanvas-end;

  .offcanvas-body {
    padding-right: 60px; // Space for the 'Need Help' floating button
  }
}

.wts-offcanvas-start,
.wts-offcanvas-end {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 70%;
  }

  @include media-breakpoint-up(lg){
    width: 50%;
  }

  &.wts-offcanvas--wide {
    @include media-breakpoint-up(md) {
      width: 80%;
    }

    @include media-breakpoint-up(lg){
      width: 80%;
    }
  }
}

.wts-offcanvas-top {
  @extend .offcanvas-top;
}

.wts-offcanvas-bottom {
  @extend .offcanvas-bottom;
}
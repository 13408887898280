.layout--panes {
  @extend .row;
  height: 65vh;

  > div {
    @extend .h-100;
  }

  .pane--list {
    @extend .d-flex;
    @extend .flex-column;
    @extend .h-100;

    .pane--list--header {
      @extend .d-flex;
      @extend .justify-content-between;
      @extend .gap-3;
      @extend .mb-3;
    }

    .pane--list--scroll {
      @extend .overflow-auto;
      @extend .flex-grow-1;
      @extend .h-0;
      @extend .overscroll-contain;
    }
  }

  .pane--detail {
    @extend .border;
    @extend .rounded;
    @extend .p-4;
    @extend .h-100;

    &.pane--detail--flush {
      @extend .p-0;

      .pane--list {
        .pane--list--scroll {
          @extend .p-4;
          @extend .pb-0;
        }

        .form-actions {
          @extend .p-4;
          @extend .pt-0;
        }
      }
    }
  }
}
#quote-customer-app {
  .quote-customer-addresses {
    margin-top: 1em;
  }

  .header-separator {
    margin: 0.5rem 2rem 1rem;
    border-top-color:$gray-200;
  }
}

.views--action,
.runView--action {
  a,
  button:not(:last-child) {
    margin-right: 0.25em;
  }
}

.special-order__row {
  padding: 1em;

  span:first-child {
    padding-left: 1em;
  }

  span:not(:last-child) {
    padding-right: 2em;
  }
}

.special-order__row-description {
  max-width: 30em;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge {
  &.badge-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

@each $color, $value in $theme-colors {
  .badge-ring-#{$color} {
    color: $value;
    border: 1px solid $value;
    background-color: transparent;
  }
}

.badge-count {
  @extend .position-absolute;
  @extend .top-0;
  @extend .start-100;
  @extend .translate-middle;
  @extend .rounded-pill;
}

.parts-tree
{

  .parts-tree-item-expander
  {
    display:block;
    height:30px;
    width:30px;
    position: absolute;
    left:5px;
    top:5px;
    cursor: pointer;

    .toggle
    {
      // horizontally and vertically center in container, always      
      @include absCenter;

      //lower the visual impact of this item
      font-size:0.75em;
      opacity:0.5;
    }
  }

  .list-group-item
  {
    padding:0;
  }
  .list-group-item .list-group
  {
    margin-left:10px;
  }


  .parts-tree-item
  {
    padding:10px 35px 10px 30px;
    position: relative;
  }

  .parts-tree-item-label label
  {
    font-weight: normal;
    display: inline-block;
    width:100%;
  }

  .parts-tree-item-checkbox
  {
    position: absolute;
    top:0;
    bottom:0;
    right:0;

    &.checked
    {
      background-color:$wts-primary-medium;
    }

    &:hover 
    {
      background-color:$light;
    }

    &.checkbox input[type=checkbox] 
    {
      margin:12px 10px;      
    }

  }
  
}
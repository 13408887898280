//
// Variables
// --------------------------------------------------

$wts-primary-dark:                #202834 !default;
$wts-primary-light:               #bec1c5 !default;
$wts-primary-medium:              #939598 !default;

$wts-text:                        #58595B !default;
$wts-text-disabled-color:         rgba($wts-text, .3) !default;
$wts-highlight:                   #F09D39 !default;
$wts-highlight-reverse:           white !default;
$wts-select-selected-item:        #96C2FE !default;

$estimate-bg:                     #f5fcff !default;
$estimate-border:                 #92c3dc !default;

$step-nav-color-primary:          $wts-primary-dark !default;
$step-nav-color-secondary:        $wts-primary-light !default;
$step-nav-active-color-primary:   $wts-highlight-reverse !default;
$step-nav-active-color-secondary: $wts-highlight !default;
$z-index-spinner:                 9999 !default;

//Copied from https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss#L730
//Need to delete after upgrade to BS 5.2
$table-striped-columns-order: odd !default;

// Ring buttons
$btn-ring-lighten: 30% !default;

$padding-base-vertical:           6px !default;
$padding-base-horizontal:         12px !default;

// Override bootstrap variables

$primary:                         $wts-highlight;
$dark:                            $wts-primary-dark;
$light:                           $wts-primary-light;

// Global textual link color.
$link-color:                      $wts-primary-dark !default;
// Link hover color
$link-hover-color:                $wts-highlight !default;
$headings-color:                  #6D6E71;

// Override navbar-dark > nav-link > color
$navbar-dark-color:               #fff !default;

// Override gray palette
$white:    #fff !default;
$gray-100: #F5F5F4 !default;
$gray-200: #E7E5E4 !default;
$gray-300: #D6D3D1 !default;
$gray-400: #A8A29E !default;
$gray-500: #78716C !default;
$gray-600: #57534E !default;
$gray-700: #44403C !default;
$gray-800: #292524 !default;
$gray-900: #1C1917 !default;
$black:    #000 !default;

// Defaults from Bootstrap, needed here so we can add to the $theme-colors map.
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$colors: (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
) !default;

$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
) !default;

// Custom theme colors
$custom-colors: (
    "x-light": $gray-100,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// Text

$text-muted:                  $gray-500 !default;

// XS button sizes
$btn-padding-y-xs:            0.0625rem !default;
$btn-padding-x-xs:            0.3125rem !default;

// Font
$font-family-sans-serif: "futura-pt", Futura, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// Add medium weight
$font-weight-medium:          500 !default;

// Fix checkboxes for Futura
$form-check-input-margin-y:   0.15rem !default;
$form-label-font-weight:      $font-weight-medium !default;

// Panels
$panel-footer-height: 64px;

// Navs
$nav-tabs-border-color: $wts-primary-medium;

// Pagination
$pagination-disabled-color: $gray-400;

// Accordion
// Match how cards look instead of the default highlight using $primary.
$accordion-button-color:          $black;
$accordion-button-bg:             rgba($black, .03); // Match $card-cap-bg.
$accordion-button-active-color:   $accordion-button-color;
$accordion-button-active-bg:      $accordion-button-bg;

// See BS5's .input-group.
// .rdt wraps .form-control so radius removal isn't selected.
.input-group {
  > .rdt {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

    > .form-control:focus {
      z-index: 3;
    }
  }

  &:not(.has-validation) {
    > .rdt:not(:last-child) {
      > .form-control {
        @include border-end-radius(0);
      }
    }
  }

  &.has-validation {
    > .rdt:nth-last-child(n + 3) {
      > .form-control {
        @include border-end-radius(0);
      }
    }
  }

  > .rdt:not(:first-child) {
    > .form-control {
      margin-left: -$input-border-width;
      @include border-start-radius(0);
    }
  }
}

.catalog-assignment {
  .button-container {
    button {
      margin: 0 5px;
      width: 85px;

      &.btn-primary {
        background-color: $lowes-interactive-01;
        color: $white;
      }
    }
  }

  .assigned-only-container {
    display: inline-block;
    margin-top: 10px;
  }

  .assign-by-client {
    ul.client-grid-group {
      max-height: 500px;
      overflow-y: scroll;
    }
  }

  select.form-control {
    width: 450px;
  }

  .client-grid-container,
  .client-view-grid-container {
    width: 650px;

    .client-grid-group {
      padding: 5px;
      width: 100%;

      .client-grid-col {
        display: inline-block;
      }

      .client-grid-col-1 {
        width: 20%;
      }

      .client-grid-col-2 {
        width: 70%;
      }

      .client-grid-col-3 {
        width: 10%;
      }
    }

    .client-grid-button-container {
      margin: 15px 0;

      button {
        margin-right: 10px;
      }
    }

    .client-grid-details {
      max-height: 600px;
      overflow-y: scroll;

      // Override BS accordion styles
      .accordion-button {
        padding: 5px;
        border-bottom: 1px solid $lowes-light-gray;

        &::after {
          display: none;
        }

        &::before {
          flex-shrink: 0;
          width: 1.25rem;
          height: 1.25rem;
          margin-left: auto;
          content: '';
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-size: 1.25rem;
          transition: transform 0.2s ease-in-out;
        }

        &:not(.collapsed)::before {
          transform: rotate(-180deg);
        }

        .accordion-item {
          width: calc(100% - 5px);
          border: none;
        }
      }

      .accordion-body {
        padding: 0 0 0 10px;

        .list-group-item {
          padding: 5px;

          &:before {
            width: 1.25rem;
            height: 1.25rem;
            margin-left: auto;
            content: ' ';
          }
        }
      }
    }

    .client-grid,
    .client-grid-header {
      .client-grid-header {
        margin: 10px 0;
        padding: 0 10px;
        list-style-type: none;
      }

      .sub-panel {
        padding: 0px 0px 0px 10px;

        .accordion-header {
          background-color: lighten($lowes-interactive-01, 60%);
        }
      }
    }
  }

  .assign-by-brand,
  .assign-by-client {
    margin-top: 30px;

    > div {
      margin-top: 20px;
    }
  }
}

.quote-attachments__replace {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.quote-attachments__preview {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  * {
    margin-right: 1em;
  }

  img {
    border-radius: $border-radius;
    width: 200px;
  }
}

.quote-attachments__list-actions {
  .btn {
    margin-bottom: .25em;

    &:not(last-child) {
      margin-right: .25em;
    }
  }
}
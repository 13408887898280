.relations__grid--table {
  margin-bottom: 2rem;
}

.relations__grid--row {
  cursor: pointer;
}

.relations__type {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.relations__type--icon {
  position: relative;
  padding-right: 0;
}

.relations__type--text {
  font-size: 0.8em;
  position: relative;
  color: $wts-text;
  user-select: none;
}

.nav-secondary {
  background: $light;
}

.nav-item.active {
  background-color: $primary;
  color: $wts-highlight-reverse;
}

.banner-text {
  min-height: 3rem;
}
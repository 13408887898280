$greyed-color: #efefef;

#serverProcessing_errors {
  display: inline-block;
  margin-right: 0.1rem;
  margin-top: 0.2rem;
  cursor: pointer;
  vertical-align: middle;
  font-size: 2em;
}

.serverProcessingErrorsDialog {
  .modal-body {
    min-height: 25vh;
    max-height: 75vh;
  }
}

/* Client Notes */
.qa-btns__client-notes {
  position: relative;
  display: block;
  float: left;
}
.client-notes__bell {
  position: relative;
}
.notes-bell__badge {
  background: $danger;
  position: absolute;
  left: -5px;
  font-size: 10px;
  top: 15px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-weight: bold;
  line-height: 1;
  color: $white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: $border-radius-lg;
}
.notes-container-list {
  width: 100%;
  list-style: none;
  padding: 0.5rem;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}
.notes-container-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0;
  margin: 0;
  white-space: pre-wrap;
  border-top: 1px solid $gray-300;
}
.notes-container-list__item:first-child {
  border: none;
}
.notes-container-list__item-text {
  display: flex;
  flex-direction: column;
}
.notes-container-list__item-text--info {
  flex: 3; /* Wider column, 3/4) */
}
.notes-container-list__item-span--date {
  @extend .text-end;
  flex: 1; /* Small column, 1/4 */
  color: $gray-300;
}
.notes__actions {
  display: flex;
  align-items: center;
  text-align: center;
}

.notes__actions [class*="col-"] {
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
}

.notes__actions--label {
  font-weight: normal;
}

@include media-breakpoint-down(lg) {
  .client-notes__container {
    right: 0;
    top: 2.5rem;
    width: 50vw;
  }
}

@include media-breakpoint-down(md) {
  .client-notes__container {
    width: 92vw;
  }
}
#estimate-app {
  display: inline-block;
}

.estimate-icon__text--horizontal {
  width: 20px;
  padding-right: 5px;
  margin-top: -2px;
}

.show-estimate_panel-header {
  div {
    display: inline-block;
  }
}

.estimate__actionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.estimate__content {
  min-height: 7em;
}

.estimate__opening {
  .estimate-react-select-container.wts-react-select-container {
    .wts-react-select__control {
      width: 100%;

      border: $form-select-border-width solid $estimate-border;
      background: $estimate-bg url('/images/estimate.svg') no-repeat 5px center;
      background-size: 18px 18px;

      &:hover,
      &--is-focused {
        $estimate-focus-color: rgba($estimate-border, $input-btn-focus-color-opacity);
        $estimate-focus-border-color: tint-color($estimate-border, 50%);
        $estimate-select-focus-box-shadow: 0 0 0 $form-select-focus-width $estimate-focus-color;

        border-color: $estimate-focus-border-color;
        box-shadow: $estimate-select-focus-box-shadow;
      }
    }
  }
}

// Styles for highlighting estimate elements
.estimate-matched-container {
  .estimate-highlight__message {
    padding: 0.1em 4px;
    background-color: $estimate-bg;
    border: 1px solid $estimate-border;
    border-radius: $border-radius;
    display: block;
    margin: 8px 1em 0;

    .estimate-highlight__icon {
      display: inline-block;
      position: relative;
      top: 1px;
      margin-right: 8px;
      float: left;
    }
  }

  .estimate-highlight__icon {
    background: url('/images/estimate.svg') no-repeat;
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    &:hover {
      cursor: pointer;
    }
  }

  .estimate-highlight {
    &.checkbox {
      background-color: $estimate-bg;
      border: 1px solid $estimate-border;
      border-radius: $border-radius;
      padding: 2px 5px;
      margin: -8px -6px 7px;
      position: relative;

      &:first-child {
        margin: 7px -6px 7px;
      }

      .estimate-highlight__icon {
        position: absolute;
        right: 3px;
        top: 3px;
      }
    }

    &.product-select-list-item {
      .estimate-highlight__icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 25px;
        height: 25px;
      }
    }
  }
}

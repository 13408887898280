// Adjust Actions dropdown button size to match Save.
#actionsButtonGroup {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.project-name {
  margin: 0px 0px 20px 0px;
  width: 95%;

  label {
    margin-bottom: 0px;
  }

  .form-text {
    margin-left: 3px;
  }
}

// Sales Person Modal
.sales-person-modal {
  .text-info {
    color: $lowes-dark-blue !important;
  }

  .project-name {
    width: 60%;
  }

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .project-name::placeholder {
    color: $lowes-field-error-red;
    opacity: 1; /* Firefox */
  }

  .sales-filters {
    margin-top: -$medium-pixels;
    background-color: $gray-100;
    padding: $medium-pixels;
  }

  .assign-someone {
    margin-top: 20px;
    display: block;
    color: $lowes-interactive-01;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-footer {
    .btn-primary {
      &:disabled {
        color: $white;
      }
    }
  }

  .sales-person-table {
    overflow-y: scroll;
    height: 45vh;

    .table-hover > tbody > tr {
      &:hover {
        background: $lowes-light-blue;
      }
    }

    .active-sales-person {
      background: $lowes-dark-blue;
      color: $white;
    }
  }
}

#btn_submit_order {
  display: none;
}

// Hide line pricing menu.
.lineItemsActionButtons > .dropdown {
  display: none;
}

//
// Email and Print Paperwork modal components
//
.report-item .user-actionable.full-width {
  width: 100%;
  display: block;
}

// Feedback icon (requires .glyphicon classes)
.input-clear {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2; // Ensure icon is above input groups
  display: block;
  width: 2.12em;
  height: 2.12em;
  line-height: 2.12em;
  text-align: center;
}

.multiLineActions {
  .custom-alternate-btn {
    margin-top: 5px;
  }
}

.not-saved-flag {
  color: $lowes-field-error-red;
  text-align: center;
  line-height: 1.45em;
}

.btn-primary {
  &.inverse {
    background-color: $white;
    border-color: $white;
    color: $lowes-interactive-01;
    font-weight: bold;

    > span,
    > span:hover {
      font-weight: inherit !important;
      color: inherit !important;
    }
  }
}

.report-item {
  margin-bottom: 12px;

  .card-header {
    padding: 0;
    background-color: $white;
    border-color: $white;
    border-bottom: none;

    .user-actionable {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba($lowes-interactive-01, 0.22);
      }
    }
  }
}

.btn-group,
.btn-group-vertical {
  display: inline-block;
}

//
// Custom defined variables for Lowes
// Styling guide may be found in PS-40752 (Branding Setup)
// Example: Lowe's dark blue is defined in 'Backyard_Style_guide.pdf'
//

// Color Theme
$lowes-dark-blue: #004990 !default;
$lowes-light-blue: #98caec !default;
$lowes-light-gray: #cccccc !default;
$lowes-lighter-gray: #eeeeee !default;
$lowes-dark-gray: #393939 !default;

$lowes-header-gray: #ededed;
$lowes-config-step-gray: #6f6f6f;
$lowes-field-error-red: #a94442;

// Numbers
$medium-pixels: 15px !default;

$btn-container-alice-blue: #f2f9fc;

$lowes-interactive-01: #0041c2;
$lowes-interactive-02: #003aae;
$lowes-interactive-03: #00349b;

$lowes-commerce-01: #338700;
$lowes-commerce-02: #2d7900;
$lowes-commerce-03: #296c00;

$lowes-savings-info: #ff0000;

@function gray($level) {
  @return darken(white, $level * 1%);
}

$lowes-disabled: gray(10);
$lowes-disabled-text: gray(3);

$lowes-ui-00: gray(3);
$lowes-ui-01: gray(7);
$lowes-ui-02: gray(11);
$lowes-ui-03: gray(15);
$lowes-ui-04: gray(44);
$lowes-ui-045: gray(85);
$lowes-ui-05: gray(92);

$transparent: rgba(255, 255, 255, 0);

$folder-selected-blue: #cce7ff;
$folder-tan: #dbb916;

$wts-primary-dark: $lowes-interactive-01;
$wts-primary-light: #d1d3d4;
$wts-primary-medium: #939598;

$wts-text: #58595b;
$wts-highlight: $lowes-interactive-02;

$border-radius-base: 0 !default;
$border-radius-large: 0 !default;
$border-radius-small: 0 !default;

$font-family-sans-serif: Helvetica, Arial, sans-serif !default;

$list-selected: #c2d6ff;

$configurator-check-green: #a2c900;
$configurator-warning-red: #e57777;

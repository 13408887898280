.btn-collapse-chevron {
  @extend .text-decoration-none;
  @extend .d-inline-flex;
  @extend .align-items-center;
  @extend .ps-0;

  &:before {
    @include icon-font-base();
    line-height: 0;
    width: 2em;

    font: var(--fa-font-solid);
    content: '\f054';

    transition: transform 0.35s ease;
  }

  &[aria-expanded='true']::before {
    transform: rotate(90deg);
  }
}

// Add carats to collapse buttons inside card and accordion headers.
.card-header,
.accordion-header {
  button[data-bs-toggle~='collapse'],
  button.controlled-collapse-trigger {
    @extend .btn-collapse-chevron;
  }
}

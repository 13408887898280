.modal-header .close {
  font-size: 28px;
}

.modal-lg-important .modal-dialog {
  width: $modal-lg;
}

.border-right-line {
  border-right: solid 2px $lowes-light-gray;
}

.margin-left-em {
  margin-left: 0.5em;
}

.style-color {
  background-color: $lowes-dark-blue !important;
  color: $white;
}

.icon-left {
  margin-left: 1.5em;
}

.empty-span {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  border-radius: 0;
  width: 1%;
  white-space: nowrap;
  display: table-cell;
}

.search-table {
  font-size: 85%;
}

.contact-phone-list {
  display: flex;

  select:first-of-type {
    width: 35%;
    margin-right: 5px;
  }

  span:first-of-type {
    width: 10%;

    .fa.fa-list-ul {
      display: block;
    }
  }
}

.contact-phone-input {
  .dropdown-menu li {
    padding: 0.5em;

    &:hover {
      background-color: $lowes-lighter-gray;
    }
  }
}

.address-table-container {
  margin-top: 10px;
  height: 240px;
  overflow-y: auto;

  .address-table {
    width: 100%;

    tr:first-child > td {
      border: none;
    }

    tr,
    tr > td:hover {
      cursor: pointer;
    }
  }
}

.customer-address-edit-form {
  .back-to-select-address {
    border: none;
    margin-bottom: 5px;
  }
}

.find-customer-btn {
  margin-left: 24%;
}

.contact-phone-select {
  width: 100%;

  select {
    width: 100%;
  }
}

.validation-error {
  display: inline-block;
  padding: 0.5em 1em;
  margin-top: 0.25em;
}

.back-to-select-address {
  margin-bottom: 15px;
}

.verified-phone-number {
  color: #228f07;
}

// Set header separator to be more prominent.
#quote-customer-app {
  .header-separator {
    background-color: $gray-400;
  }

  .form-control-plaintext {
    color: $wts-text;
  }
}

// Prevent the BS black table row hover text color from showing - set it to
// white.
.address-table-container .table-hover > tbody > tr.style-color:hover > * {
  color: $white;
}

// Makes available days in calendar more distinguishable from unavailable ones
.rdtPicker {
  td.rdtDay {
    &:not(.rdtDisabled, .rdtNew) {
      color: $wts-text;
    }
    &.rdtDisabled,
    &.rdtNew,
    &.rdtOld {
      color: $wts-text-disabled-color;
      text-shadow: none;
    }
  }
}

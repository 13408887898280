.quote-line-notes {
  display: flex;
  flex-wrap: wrap;

  a:link, a:visited {
    text-decoration: none;
    color: $wts-text;
  }

  .note {
    &:not(:last-child) {
      margin-right: 1em;
    }

    margin-bottom: .5em;
    flex: 0 0 20%;
    min-height: 10em;
    transition: transform .2s;

    &.note-empty {
      border: 2px dashed $card-border-color;
      position: relative;
      text-align: center;
      opacity: .8;
      padding: .1em;
      border-radius: $border-radius;
      position: relative;
      cursor: pointer;

      div {
        @include absCenter;

        span {
          font-size: 2.5em;
        }

        h4 {
          margin-top: .3em;
        }
      }

      &:hover  {
        opacity: 1;
        transform: translateY(-.3em) scale(1.03);
        box-shadow: 0px 10px 20px rgba($secondary, .2);
      }
    }
  }
}

.notes-modal {
  width: 75%;

  textarea {
    width: 100%;
    height: 5em;
  }

  .characters-remaining {
    margin: 0em;
    padding-top: .1em;
    text-align: right;

    &.zero {
      color: $primary;
    }
  }

  .modal-footer {
    border-top: none;
  }
}
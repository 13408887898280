//PS-85292 - Added button logic to selectors.
.nav.nav-tabs li {
  a,
  button {
    @include lowes-btn-nav;
  }

  &.active a,
  button {
    &.active,
    &:link,
    &:visited,
    &:hover {
      @include lowes-button($lowes-interactive-02, $lowes-interactive-02);
    }

    &:focus {
      @include inner-border($white);
    }
  }
}

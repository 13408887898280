.users-grid__search {
  > form {
    width: 70%
  }
  > a {
    @extend .align-self-baseline;
  }
}

.user-row {
  cursor: pointer;
}

.user-row__actionLinks {
  :not(:last-child) {
    margin-right: .25em;
  }
}

.user-input__autoSave {
  display: flex;
  align-items: center;

  >.input-group, >.form-group {
    width: 100%;
  }
}

.user-input__saved--success {
  color: $success;
  margin-left: .5em;
}

.password-match {
  margin-top: .5em;
}

.new-user-action {
  .btn:not(:last-child)  {
    margin-right: .2rem;
  }
}

.user-settings__override-security {
  display: flex;
  align-items: center;
}

.autoAddNewBrands {
  display: flex;
  align-items: center;
}

.activationCode {
  display: flex;
  margin-bottom: .5em;

  >* {
    margin-right: .5em;
  }
}

.newActivationCode {
  display: flex;
  justify-content: space-between;
}

.react-tel-input .form-control {
  width: 100%;
}

.brandsTable, .settingsTable {
  height: 65vh;
  overflow: auto;
}

.clientsTable {
  height: 40vh;
  overflow: auto;
  background-color: $gray-200;
}

.settingsTable {
  height: 55vh;
  overflow: auto;
  background-color: $gray-200;
}

.applicationsTable {
  height: 40vh;
  overflow: auto;
}

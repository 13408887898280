#webcp-app {
  // Hide options under user dropdown in SPA.
  .dropdown-menu.dropdown-menu-end {
    li {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) hr {
        display: none;
      }
    }
  }

  .create-promo-heading {
    display: flex;

    p.text-danger {
      margin: 10px 0px 0px 10px;
    }
  }

  .has-addon {
    div:first-of-type {
      width: 48.5%;
      padding-left: 15px;
    }

    label:first-of-type {
      padding-left: 15px;
    }
  }

  .lead-time-data,
  .promo-table-data {
    .btn {
      // PS-85053 - Color pencil/edit icons blue on Promo Rows
      span.wts__i--pencil {
        color: $lowes-interactive-02 !important;
      }
    }
  }

  // PS-85055 - Keep date icon from shifting in error/invalid state.
  .create-promo {
    form {
      input[type='date'] {
        &.is-invalid {
          padding-right: 0.75rem;
        }
      }
    }
  }
}

.btn-transparent-bg {
  background: transparent;
}

.favorite-tile__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.favorites-tree {
  overflow: auto;
  height: 11.25rem;
  border: 1px solid $lowes-ui-04;

  .wts__i-- {
    width: 1em;
  }

  .inline-edit-button {
    width: 2.5rem;
  }

  .rename-node-group {
    display: inline-flex !important;
    width: auto;

    input {
      display: inline !important;
      width: 8.75rem !important;
    }
  }

  ul {
    list-style-type: none;

    &:first-child {
      padding-left: 0;
    }

    .tree-item {
      padding: 0.25rem;
      cursor: pointer;
      user-select: none;
    }

    .tree-item-expander {
      cursor: pointer;
    }

    .tree-item-selected {
      background-color: $folder-selected-blue;
    }

    .tree-icon {
      width: 1.125rem;
      text-align: center;
    }
  }
}

.folder-icon {
  color: $folder-tan;
}

.xl-icon {
  font-size: 11em;
  padding-right: 0;
}

.grouped-icon {
  margin-top: 2px !important;
}

.favorite-tile {
  min-width: 15rem;
}

.favorite-back-btn {
  padding-top: 0.1rem;
  padding-bottom: 0rem;
}

@include media-breakpoint-up(md) {
  .favorite-back-btn {
    transition: all 0.2s;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

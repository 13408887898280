.location
{
  /* indent the data, out-dent the labels */
  .location-address
  {
    margin-bottom:1em;
    margin-left:1ex;
  }

  .location-contact 
  {
    margin-left:1ex;
    > div {
      word-break: break-word;
    }
  }

  .location-address label,
  .location-contact label
  {
    font-size:0.8em;
    margin-left:-1ex;
  }
}

.location.location-US.location-condenced /* Render the address as written on a mailing envelope */
{
  .location-address
  {
    margin-left:0;
  }

  .location-Line1,
  .location-Line2,
  .location-City,
  .location-State,
  .location-Country,
  .location-PostalCode  
  {
    label
    {
      display:none;
    }
  }

  .location-City, 
  .location-City div,
  .location-State,
  .location-State div,
  .location-PostalCode,
  .location-PostalCode div
  {
    display:inline-block;
  }

  .location-City div::after
  {
    content:",";
    display:inline-block;
    margin-right:1ex;
  }

  .location-State div
  {
    margin-right:1ex;
  }

}

.table {
  // Target th & td
  > :not(caption) > * > * {
    padding: 0.5em 1em;
    border-color: $wts-primary-medium;
  }
}

.table-supercondensed {
  // Target th & td
  > :not(caption) > * > * {
    padding: calc($table-cell-padding-y / 1.5) calc($table-cell-padding-x / 1.5);
  }
}

.table-borderless {
  // Target th & td
  > :not(caption) > * > * {
    border: none;
  }
}

th.nowrap,
td.nowrap {
  white-space: nowrap;
}

.table > tbody > tr.table-row-border-heavy > td {
  border-top-width: 2px;
}

// For react-bootstrap-modal
.modal-open .modal {
  display: block;
}

// a tags without an href (e.g. used with onClick) were getting selection cursor instead of pointer
.dropdown-menu a {
  cursor: pointer;
}

// Add pointer to elements with an action (e.g. a tag with an onClick and no href).
.user-actionable {
  cursor: pointer;
}

.list-group-root.list-group {
  @extend .card;
  padding: 0;
  overflow: hidden;

  .toggle {
    cursor: pointer;
  }

  .checkbox.disabled label {
    color: $input-disabled-bg;
  }

  .checkbox input[type="checkbox"] {
    margin: 0;
  }

  .indent {
    margin-left: 1em;
    margin-right: 0.5em;
  }

  .list-group {
    margin-bottom: 0;
  }

  .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
  }

  > :first-child > .list-group-item:first-child {
    border-top-width: 0;
  }
}

.image-has-lightbox-trigger {
  position: relative;
  display: block;

  img {
    cursor: pointer;
  }

  svg[data-icon] {
    display: block;
    position: absolute;
    bottom: 0.25em;
    right: 0.25em;
    font-size: 1.5em;
    text-shadow: 0 0 5px $white;
  }
}

.active-flash {
  animation-name: active-flash-animation;
  animation-duration: 1s;
}

@keyframes active-flash-animation {
  from { background-color: #EBEB75; }
  to   { background-color: inherit; }
}

.redux-toastr {
  font-size: 0.9em;

  .toastr {
    .toastr-left-container {
      width: 40px;

      .holder {
        width: 35px;
        height: 35px;
        margin-top: -17px;
        line-height: 30px;
      }
    }
  }
}

.wts-callout {
  padding: $padding-base-vertical $padding-base-horizontal;
  background-color: $gray-200;
  border-left: 0.5em solid $primary;
  margin-bottom: 1em;
  font-size: $font-size-sm;
  display: flex;
  align-items: center;

  .wts-callout--inner {
    flex: 1 1 auto;
    display: flex;
  }
  .wts-callout--actions {
    display: flex;
    text-align: right;
  }
}

.overscroll-contain {
  overscroll-behavior-y: contain;
}

.h-0 {
  height: 0;
}

.fw-medium {
  font-weight: $font-weight-medium;
}
.shipDates {
  @include spinner;

  &-wait-spinner > .spinner {
    opacity: 1;
    display: block;
  }
}
.ship-date__quote {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.ship-date__lines {
  margin-top: 1rem;
}

.ship-date__quoteDate {
  margin: 0 .5rem 0 .5rem;
}

.ship-date__lineDate {
  width: 10rem;
}

.ship-date__lineDate--disabled, .ship-date__multi--disabled, .ship-date__quoteDate--disabled {
  pointer-events: none;
  opacity: 0.7;
}

.ship-date__multi {
  padding: 1rem;

  // Move calendar to top of the modal
  // The MultiLineAction modal has fixed position and fixed height, so we are probably okay for now.
  .rdtPicker {
    top: -14.5rem
  }
}

.ship-date__columnHeader {
  a {
    position: absolute;
  }
}

.ship-date__multiDate {
  position: relative;
  a {
    right: -1.2rem;
  }
}


.region-debug {
  border: 1px solid $red-200;

  &.region-debug--customized {
    > svg,
    > .region-debug--ui-trigger-wrapper > svg { @extend .text-success; }
  }

  &.region-debug--deprecated {
    > svg,
    > .region-debug--ui-trigger-wrapper > svg { @extend .text-light; }
  }

  &.region-debug--customized.region-debug--deprecated {
    > svg,
    > .region-debug--ui-trigger-wrapper > svg { @extend .text-danger; }
  }
}

.popover.region-debug--ui {
  max-width: $popover-max-width * 2;

  .popover-body {
    padding: 0;
  }

  .region-context-info {
    max-height: 20em;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
  }
}

.region-debug-toggle {
  .form-check-input[type=checkbox] {
    @include font-size($input-font-size-sm);
  }
  .col-form-label-sm {
    padding-top: 0;
    padding-bottom: 0;
  }
}
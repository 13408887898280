.promotion-filters {
  margin-bottom: 1.25em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $lowes-light-gray;
  padding: 0.5em;
  border: 2px solid $black;
}

.field-error {
  padding: 0.25em 0.5em;
  margin: 0.25em 0;
  border-radius: 3px;
  border: 1px solid $lowes-field-error-red;
}

.quote-pricing .col-md-5 strong {
  line-height: 34px;
}

.quote-pricing .col-md-7 button {
  margin: none;
}

.promo-desc {
  //used on line-items tab
  display: block;
  text-align: right;
  margin: -10px 10px 5px;
  font-size: 13px;

  span {
    display: block;
  }
}

.promo-savings {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .promo-percentage {
    color: $lowes-savings-info;
  }
}

.promo-end-date {
  padding: 0.3em 0 1em;
  font-size: 0.8em;
  font-weight: bold;

  .promo-date {
    color: $lowes-savings-info;
  }
}

.sortable-header {
  cursor: pointer;
}

.custom-email-dialog {
  font-size: initial;

  input:invalid,
  textarea:invalid {
    border: $lowes-field-error-red 1px solid;
  }

  .dialog-header {
    font-size: larger;
  }

  .dialog-body {
    label {
      color: #686868;
      display: block;
      margin-bottom: 15px;

      input {
        display: block;
        width: 100%;
        font-weight: 400;
        font-size: small;
        min-height: 30px;
      }

      textarea {
        width: 100%;
        display: block;
      }

      span {
        font-size: small;
        font-weight: 400;
      }
    }
  }
}

#global-changes-app {

  .suggestion-drawing {
    margin: 1em;
    text-align: center;
    
    img {
      width: 50%;
      max-width: 200px;
    }
  }
  
  .footerContainer.footerContainer { // for higher precedence
    justify-content: flex-end;
  }
}

.global-changes-custom-pricing-report {
  min-height: 25vh;
  max-height: 75vh;
  overflow: auto;
}

header.navbar {
  .container {
    justify-content: unset;

    .brandbar-logo-container {
      width: 7.5rem;
    }

    .brandbar-logo {
      background-image: url('https://36qyuzoj7fgnibljtmes.blob.core.windows.net/marketingimages/lowes/logo.png');
      width: 7.5rem;
    }
  }

  .custom-brand-info {
    h4 {
      color: $lowes-dark-blue;
      font-size: 1.12em;
    }
  }
}

/* main navigation buttons*/
.navbar .navbar-expand-lg {
  .quotes,
  .orders,
  .reports,
  .customers,
  .favorites {
    a {
      @include lowes-btn-nav;
    }

    &.active a {
      &:link,
      &:visited,
      &:hover {
        @include lowes-button($lowes-interactive-02, $lowes-interactive-02);
      }

      &:focus {
        @include inner-border($white);
      }
    }
  }
}

.dropdown-menu {
  border-radius: 0 !important;
}

.navbar.navbar-expand-lg {
  background-color: $lowes-header-gray !important;
  font-size: 0.9em;
}

#navbarSupportedContent {
  // Fix hover of new quote button.
  .nav-item.new-quote {
    a {
      cursor: pointer;
    }
  }
}

// Bootstrap 4 drops this class, so we're recreating it.
.page-header {
  @include clearfix();

  @extend .pb-2;
  @extend .mt-3;
  @extend .mb-3;

  h1 {
    @extend .float-start;
    @extend .mb-1;

    .editable-error-block {
      font-size: $font-size-base;
    }
  }
}

// Most variables are from react-redux-toastr
.notifications {
  width: $toastr-width;
  position: fixed;
  z-index: 99999999;
  padding: 0 $defaultSpace;
  top: 0;
  right: 0;

  .notification {
    @extend .d-flex;
    @extend .m-2;
    @extend .text-white;
    @extend .border-0;

    i {
      @extend .fs-3;
      @extend .flex-shrink-0;
      @extend .me-2;
    }

    &.alert-success {
      background-color: $successBgColor;
    }
    &.alert-info {
      background-color: $infoBgColor;
    }
    &.alert-warning {
      background-color: $warningBgColor;
    }
    &.alert-danger {
      background-color: $errorBgColor;
    }

    .notification--content {
      @extend .text-break;
    }
  }
}
// Icons inside a button get padding.
.btn:not(.btn-icon-only):not(.wts-button) {
  > svg[data-icon],
  > .fa-solid, > .fa-regular, > .fa-light, > .fa-thin, > .fa-duotone, {
    &:first-child {
      padding-right: 0.5em;
    }
    &:last-child {
      padding-left: 0.5em;
    }
  }
}

// Icons padding in other components.
.dropdown-menu,
.nav-link {
  svg[data-icon],
  > .fa-solid, > .fa-regular, > .fa-light, > .fa-thin, > .fa-duotone, {
    padding-right: 0.25em;
  }
}

// Fixed-width inside dropdown menu items gets padding
.dropdown-menu {
  .dropdown-item {
    .fa-fw {
      @extend .me-1;
    }
  }
}

/*

Old icon classes and their replacements:

(starting with wts__i--)

  currency:           fa-solid fa-dollar-sign
  stop-type:          fa-solid fa-triangle-exclamation
  error-circle:       fa-solid fa-circle-exclamation
  price-books:        fa-solid fa-book
  check:              fa-solid fa-check
  check-circle:       fa-solid fa-circle-check
  times-circle:       fa-regular fa-circle-xmark
  ban:                fa-solid fa-ban
  history:            fa-solid fa-history
  info:               fa-regular fa-circle-info
  close:              fa-solid fa-times
  left-arrow:         fa-solid fa-arrow-left
  long-left-arrow:    fa-solid fa-left-long
  right-arrow:        fa-solid fa-arrow-right
  plus:               fa-solid fa-plus
  plus-circle:        fa-regular fa-circle-plus
  minus:              fa-solid fa-minus
  minus-circle:       fa-regular fa-circle-minus
  share:              fa-solid fa-share-nodes
  edit:               fa-solid fa-edit
  view:               fa-solid fa-search
  sortup:             fa-solid fa-sort-up
  sortdown:           fa-solid fa-sort-down
  trash:              fa-regular fa-trash-can
  times:              fa-solid fa-times
  files:              fa-regular fa-file-lines
  left:               fa-solid fa-chevron-left
  right:              fa-solid fa-chevron-right
  down:               fa-solid fa-chevron-down
  up:                 fa-solid fa-chevron-up
  update:             fa-solid fa-arrow-rotate-right
  download:           fa-solid fa-download
  print:              fa-solid fa-print
  copy:               fa-regular fa-clone
  remove:             fa-solid fa-square-minus
  calendar:           fa-solid fa-calendar-days
  wrench:             fa-solid fa-wrench
  random:             fa-solid fa-random
  map-marker:         fa-solid fa-location-dot
  user-plus:          fa-solid fa-user-plus
  users:              fa-solid fa-users
  user:               fa-solid fa-user
  address-card:       fa-solid fa-address-card
  settings:           fa-solid fa-cogs
  favorite:           fa-regular fa-heart
  ellipsis:           fa-solid fa-ellipsis-h
  alt-stop:           fa-regular fa-hand
  fork:               fa-solid fa-code-branch
  pencil:             fa-solid fa-pencil
  bell-o:             fa-regular fa-bell
  comments-o:         fa-regular fa-comments
  warning:            fa-solid fa-triangle-exclamation
  star:               fa-solid fa-star
  star-o:             fa-regular fa-star
  lock:               fa-solid fa-lock
  unlock:             fa-solid fa-unlock
  handshake-o:        fa-regular fa-handshake
  list:               fa-solid fa-list
  list-alt:           fa-regular fa-list
  save:               fa-regular fa-floppy-disk
  pin:                fa-solid fa-thumbtack
  photo:              fa-regular fa-image
  bars:               fa-solid fa-bars
  arrow-circle-right: fa-solid fa-circle-arrow-right
  arrow-circle-down:  fa-regular fa-circle-down
  mull:               fa-solid fa-th-large
  undo:               fa-solid fa-undo
  question-circle:    fa-solid fa-circle-question
  question-circle-o:  fa-regular fa-circle-question
  files-o:            fa-regular fa-copy
  money:              fa-regular fa-money-bill-1
  key:                fa-solid fa-key
  clipboard:          fa-regular fa-clipboard
  ticket:             fa-solid fa-ticket-simple
  columns:            fa-regular fa-columns
  search-plus:        fa-solid fa-search-plus
  cloud-upload:       fa-solid fa-cloud-arrow-up
  show:               fa-regular fa-eye
  hide:               fa-regular fa-eye-slash
  sign-in:            fa-solid fa-right-to-bracket
  sign-out:           fa-solid fa-right-from-bracket
  file-pdf:           fa-regular fa-file-pdf
  change:             fa-solid fa-retweet
  tag:                fa-solid fa-tags
  truck:              fa-solid fa-truck
  folder-open-o:      fa-regular fa-folder-open
  paper-clip:         fa-solid fa-paperclip
  asterisk:           fa-solid fa-asterisk
  percent:            fa-solid fa-percent
  arrows-alt:         fa-solid fa-maximize
  external-link:      fa-solid fa-up-right-from-square
  barcode:            fa-solid fa-barcode

*/
// Hide empty secondary nav bar.
.navbar.nav-secondary {
  display: none !important;
}

div#nav {
  margin-bottom: 4px;

  ul.navbar-nav {
    font-size: 0.9em;

    li.customers,
    li.orders,
    li.plans,
    li.reports {
      display: none;
    }

    > li {
      margin-right: 12px;

      > a {
        background-color: $lowes-ui-03;
        color: $lowes-ui-05;
        border: 1px solid $lowes-ui-04;

        &:hover {
          background-color: $lowes-interactive-02;
          border-color: $lowes-interactive-02;
          color: $white;

          > span {
            color: $white;
          }
        }

        > span {
          color: $lowes-ui-05;
        }
      }

      &.active > a {
        background-color: $lowes-interactive-02;
        border-color: $lowes-interactive-02;
        border-radius: 0;
        color: $white;
        cursor: pointer;

        span {
          color: $white;
        }
      }

      &.quotes {
        &.not-active > a {
          background-color: $lowes-ui-03;
          color: $lowes-ui-05;
          border: 1px solid $lowes-ui-04;

          &:not(:hover) > span {
            color: $lowes-ui-05;
          }

          &:hover {
            background-color: $lowes-interactive-02;
            border-color: $lowes-interactive-02;
            color: $white;
          }
        }
      }
    }
  }

  #quick-find-app {
    display: none;
  }
}

.datagrid {

  .datagrid__header {
    @extend .mb-3;

    .datagrid__search {
      display: flex;

      form {
        flex-grow: 1;
        margin-right: 1rem;
      }
    }
  }

}
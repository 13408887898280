@mixin icon-font-base {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

// deprecated
// Generate the extra small columns
@mixin make-grid-sm-column($columns, $grid-columns: $grid-columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(calc($columns / $grid-columns));
  min-height: 1px;
  padding-left:  calc($gutter / 2);
  padding-right: calc($gutter / 2);
}

// deprecated
// Generate the small columns
@mixin make-grid-md-column($columns, $grid-columns: $grid-columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  calc($gutter / 2);
  padding-right: calc($gutter / 2);

  @include media-breakpoint-up(md) {
    float: left;
    width: percentage(calc($columns / $grid-columns));
  }
}

// deprecated
// Generate the medium columns
@mixin make-grid-lg-column($columns, $grid-columns: $grid-columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  calc($gutter / 2);
  padding-right: calc($gutter / 2);

  @include media-breakpoint-up(lg){
    float: left;
    width: percentage(calc($columns / $grid-columns));
  }
}

// deprecated
// Generate the large columns
@mixin make-grid-xl-column($columns, $grid-columns: $grid-columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  calc($gutter / 2);
  padding-right: calc($gutter / 2);

  @include media-breakpoint-up(xl){
    float: left;
    width: percentage(calc($columns / $grid-columns));
  }
}
// deprecated
@mixin make-xl-column-offset($columns) {
  @include media-breakpoint-up(xl){
    margin-left: percentage(calc($columns / $grid-columns));
  }
}
// deprecated
@mixin make-xl-column-push($columns) {
  @include media-breakpoint-up(xl){
    left: percentage(calc($columns / $grid-columns));
  }
}
// deprecated
@mixin make-xl-column-pull($columns) {
  @include media-breakpoint-up(xl){
    right: percentage(calc($columns / $grid-columns));
  }
}

@mixin spinner {
  &.wait-spinner {
    .spinner {
      opacity: 1;
      display: block;
      position: absolute;
    }
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@mixin view_more {
  background-color: transparent;
  padding-top: 0px;

  .btn {
    border: none;
    color: $wts-text;
  }

  a {
    position: relative;
    padding-top: .2em;
  }

  a::after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 90%;
    width: 0;
    height: 0;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-top: .5em solid $wts-text;
  }
}
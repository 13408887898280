// Cards with nested tables that also have no bottom margin should not have a
// bottom border on the last row
.card > .collapse > .table.mb-0,
.card > .table.mb-0 {
  > tbody > tr:last-child {
    > td, > th {
      @extend .border-bottom-0;
    }
  }
}

.card.card--selected {
  background-color: $gray-200;
}
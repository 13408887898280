.login-footer {
  ul {
    list-style: none;
    padding-left: 0px;
  }

  li {
    text-align: center;
  }
}

.login-password__action {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @include media-breakpoint-up(lg){
    flex-direction: row;
  }
}

.login-password__action-left {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg){
    justify-content: left;
  }
}

.login-password__action-right {
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-down(lg){
    a, button {
      width: 100%
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.login-email__next, .login-email__register {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg){
    flex-direction: row-reverse;
  }
}

.login-email__register {
  justify-content: center;
}

.fix-your-account_action {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;

  @include media-breakpoint-up(lg){
    flex-direction: row;
  }
}

.password-strength-meter {
  height: .5em;
}
.wts-react-select-container {

  .wts-react-select__control {
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
    @include box-shadow($form-select-box-shadow);
    @include transition($form-select-transition);

    &:hover,
    &--is-focused {
      border-color: $form-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
      }
    }
  }
  .wts-react-select__menu {
    z-index: $zindex-fixed + 1
  }

  .wts-react-select__option.wts-react-select__option--is-selected {
    color: $wts-text;
    background-color: $wts-select-selected-item;
  }
}
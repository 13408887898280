@import './variables';
@import '~$webcp-sass/app.scss';

@import './lowes-mixins';

@import './components/buttons';
@import './components/forms';
@import './components/tabs';
@import './components/submenu';
@import './components/header';
@import './components/nav';

@import './pages/quote-actions';
@import './pages/favorites';
@import './pages/catalog-assignment';
@import './pages/login';
@import './pages/quotes';
@import './pages/configurator';
@import './pages/line-items';
@import './pages/my-quotes';
@import './pages/home';
@import './pages/quote-customer';
@import './pages/quote-copy';
@import './pages/promotions';
@import './pages/webcp';
@import './pages/patch-area';

// Adjust positioning of quote title container to account for email and paperwork buttons
.page-header {
  .row .col-lg-6 h1.h3 {
    margin-top: 1.25em;
  }
}

form.card.form {
  flex-direction: row;
}

.redux-toastr .toastr,
.notification {
  border-radius: 0;
}

form {
  .is-invalid {
    background: none !important;
  }
}

// Make the input text color red on invalid fields. While not ideal, that is the
// current "Standard" in Lowe's customizations. Per Rebecca, hopefully we can
// remove this during a future upgrade and establish a new standard.
input.form-control.is-invalid {
  color: $red;

  &::placeholder {
    color: $red;
  }
}

label:not(.form-check-label) {
  @extend .fw-bold;
}

select,
.input-group-text {
  border-radius: 0 !important;
}

//PS-84667 - Offcanvas slide out footers should be light gray.
.offcanvas-footer {
  .navbar {
    background-color: $wts-primary-light !important;
  }
}

// PS-84669 - Update active nav-pills to be Lowes blue.
.nav-pills {
  > li.active > a {
    background-color: $lowes-interactive-01;
    border-color: $lowes-interactive-01;
  }
}

// Consistent styles for form labels.
.theme-text-info {
  color: $lowes-dark-blue !important;
}

// Modal styles
.lowes-modal {
  .modal-footer {
    border-top: none;
  }
}

#line-items-app {
  .lineItem_actionButtons .btn {
    border: 0;
  }

  // Relocate condensed view toggle due to addition of My Favorites button.
  #lineItemsCondensedView {
    margin-left: -2.5em;
  }

  // Hide Display button in footer - PS-105147
  nav.fixed-bottom .wts-button.btn-link {
    display: none;
  }

  // Hide the button names on the line item.
  .lineItem_actionButtons {
    .wts-button.btn {
      span span {
        display: none;
      }
    }
  }
}

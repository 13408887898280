.favorite-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    min-height: 200px;
  }
}

.favorite-tile-footer__configure, .favorite-tile-footer__manage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.favorite-header {
  display: flex;
  align-items: center;
}

.favorite-table-description {
  @extend .d-inline-block;
  @extend .text-truncate;

  max-width: 150px;

  @include media-breakpoint-up(md) {
    max-width: 250px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 350px;
  }
}

.favorite-table-xl-description {
  @extend .favorite-table-description;

  @include media-breakpoint-up(xl) {
    max-width: 650px;
  }
}
.favorite-tiles {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -0.5em 0 -0.5em;
}

.favorite-tile {
  flex: 1 1 200px;
  margin: 0 0.5em 1em 0.5em;
  text-align: center;
  max-width: 300px;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.06);
}

.favorite-tile--selectable {
  cursor: pointer;
}

.favorite-tile__image {
  //padding-bottom: 65%;

  img {
    //position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: 0 auto;

    max-height: 10rem;
  }
}

.favorite-tile__name {
  margin-top: 1rem;
}

.favorite-tile__menu {
  display: flex;
  justify-content: flex-end;
}

.favorite-save {
  display: flex;
}

.favorite-data {
  @include media-breakpoint-up(md){
    display: flex;
    align-items: center;
    flex: 1;
  }
}

.favorite-lineInfo {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  text-align: center;
  margin: 0 1rem;
}

.favorite-lineDescription {
  margin-top: .5rem;
}

.favorite-name-client {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.favorite__form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  display: flex;
  flex: 0 0 65%;
  min-height: 44px; /* IE issue hack */
}

.favorite__form-input {
  display: inline-block;
  border: none;
  width: 88%;
  padding-left: 1rem;
  font-size: 1.5em;
  outline: none;
}

.favorite__form-submit {
  border-radius: $border-radius;
}

.favorite-search {
  display: flex;
  align-items: center;
}

.favorite-back {
  font-size: 1.5em;
  align-self: flex-start;

  @include media-breakpoint-up(md){
    margin: -20px;
    padding: 20px;

    transition: all .2s;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }
}

.favorite__not-found {
  text-align: center;

  small {
    margin-left: .5rem;
  }
}

.radio > .favorite-save-radio {
  font-weight: 500;
}

.favorite-table-description {
  @extend .d-inline-block;
  @extend .text-truncate;

  max-width: 150px;

  @include media-breakpoint-up(md) {
    max-width: 250px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 350px;
  }
}